<template>
    <div class="exdlist-leverage">
        <h3 v-html="$content('timer_toogle_modal_title')"></h3>
        <transition name="fade">
            <div v-if="variants">
                <div class="exdlist-leverage_item" v-for="(item, id) in variants" :key="id" @click="onSelect(id)">
                    <div class="exdlist-leverage_item_body" :class="{'exdlist-leverage_item_active': id === selectedVariantId}">
                        <img :src="this.imageSelector($store.getters.static.favicon)" alt="">
                        <span>{{ item.multiplier }}</span>
                        <p v-html="$content('timer_toogle_modal_btn')"></p>
                    </div>
                    <div class="exdlist-leverage_item_info">
                        <p v-html="$content('timer_toogle_modal_apply')"></p>
                        <span>${{ item.total_amount }}</span>
                    </div>
                </div>
            </div>
        </transition>
        <div class="exdlist-leverage_rules">
            <b-form-checkbox
                style="margin-left: 10px"
                id="credit-shoulder-rules"
                v-model="userAccept"
                name="credit-shoulder-rules"
                :value="true"
                :unchecked-value="false"></b-form-checkbox>
            <span v-html="$content('leverage_rules')"></span>
        </div>
        <ProjectButton @click.native="invest" variant="app">
            <div v-html="$content('timer_toogle_modal_change')"></div>
        </ProjectButton>
        <ProjectButton @click.native="close" variant="paper">
            <div v-html="$content('timer_toogle_modal_cancel')"></div>
        </ProjectButton>
    </div>
</template>

<script>
import ProjectButton from "../Buttons/ProjectButton";
export default {
    name: "DealLeverage",
    props: ['params'],
    components: {ProjectButton},
    data() {
        return {
            userAccept: false,
            variants: null,
            selectedVariantId: null
        }
    },
    computed: {
        amount() {
            return this.$store.getters.leverageAmount
        }
    },
    methods: {
        close() {
            this.$modal.close();
            this.$store.dispatch('toggleLeverage', null);
        },
        invest() {
            if (this.selectedVariantId) {
                const data = {
                    leverage_id: this.selectedVariantId,
                    agreetment: this.userAccept,
                    chain_id: this.$store.getters.leverageDealId
                }
                this.$deals.run('invest', data)
                    .then(res => this.onClose())
            } else {
                this.$modal.open('Notify', {key: 'leverage_select_id', type: 'fail'})
            }
        },
        onSelect(id) {
            this.selectedVariantId = id;
        }
    },
    watch: {
        amount: {
            handler: function (val) {
                this.$http('exchanges').getLeverageVariants(val)
                    .then(res => this.variants = this.$payloadHandler(res).result)
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixins";

.exdlist-leverage {
    width: 100%;
    background: white;
    border-radius: 15px;
    padding: 23px 26px;
    box-shadow: 0 0 8px 0 rgba(0,0,0, 0.42);
    transition: 0.3s;

    @include only-xs {
        padding: 23px 10px;
    }

    &_rules {
        display: flex;
        align-items: center;
        margin: 12px 0 22px 0;

        & span {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 14px;
            color: var(--main-dark);
        }
    }

    & h3 {
        font-family: Acrobat-Light, sans-serif;
        font-size: 18px;
        color: grey;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &_item {
        border-radius: 200px;
        background: #f3f3f3;
        height: 90px;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
        justify-content: center;

        &_active {
            background: var(--main-app-transparent);

            & span {
                color: black !important;
            }

            & p {
                color: black !important;
            }
        }

        &_info {
            margin-left: 21px;

            @include only-xs {
                margin-left: 10px;
            }

            & p {
                font-family: Montserrat-Medium, sans-serif;
                font-size: 12px;
                color: var(--main-app);
                margin-bottom: 0px;
                line-height: 1;
            }

            & span {
                font-size: 22px;
                font-family: Acrobat-Light, sans-serif;
                color: var(--main-dark);
                line-height: 1;

                @include only-xs {
                    font-size: 18px;
                }
            }
        }

        &_body {
            width: 170px;
            height: 43px;
            border-radius: 200px;
            border: 2px solid var(--main-app);
            padding-left: 19px;
            display: flex;
            align-items: center;

            @include only-xs {
                width: unset;
                padding: 0 10px;
            }

            &:hover {
                background: #78d82247;
                cursor: pointer;

                & p, span {
                    color: var(--main-dark);
                }
            }

            & img {
                width: 17px;
                margin-right: 7px;
            }

            & span {
                margin-right: 7px;
                font-family: Acrobat-Light, sans-serif;
                font-size: 21px;
                color: grey;
                line-height: 1;
                margin-bottom: 3px;
            }

            & p {
                margin-bottom: 0;
                font-family: Montserrat-Medium, sans-serif;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--main-app);
                line-height: 1;

                @include only-xs {
                    font-size: 10px;
                }
            }
        }
    }
}
</style>