<template>
    <div class="shoulder-modal">
        <DealLeverage :params="params" />
    </div>
</template>

<script>
import DealLeverage from "../Deals/DealLeverage";
export default {
    name: "Shoulder",
    components: {DealLeverage},
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .shoulder-modal {
        border-radius: 10px;
        width: 600px;

        @include only-xs {
            width: 100%;
        }
    }
</style>